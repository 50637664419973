export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more error)|(and {count} more errors)|(and {count} more errors)",
        "404 - Page not found": "404 - Page not found",
        "8-12 hours": "8-12 hours",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "A decryption key is required.": "A decryption key is required.",
        "About store text": "About store text",
        "About store title": "About store title",
        "About us": "About us",
        "Active": "Active",
        "Active promotion": "Active promotion",
        "Address & contact": "Address & contact",
        "Administrator": "Administrator",
        "All rights reserved.": "All rights reserved.",
        "All vacancies": "All vacancies",
        "Apply directly": "Apply directly",
        "Apply now": "Apply now",
        "at Jumbo Bas Bobeldijk": "at Jumbo Bas Bobeldijk",
        "Available in:": "Available in:",
        "Back to home": "Back to home",
        "Banner": "Banner",
        "Birthday *": "Birthday *",
        "Choose file": "Choose file",
        "City": "City",
        "Company name": "Company name",
        "Contact": "Contact",
        "Contact Cards": "Contact Cards",
        "Content": "Content",
        "Content Detail Page": "Content Detail Page",
        "Content Repeater": "Content Repeater",
        "Cta": "Cta",
        "Current Actions": "Current Actions",
        "Delete filters": "Delete filters",
        "Description": "Description",
        "description": "description",
        "Different opening hours": "Different opening hours",
        "Different openings hours": "Different openings hours",
        "Different openingshours": "Different openingshours",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Email": "Email",
        "Encrypted environment file already exists.": "Encrypted environment file already exists.",
        "Encrypted environment file not found.": "Encrypted environment file not found.",
        "End": "End",
        "Enter working hours here": "Enter working hours here",
        "Environment file already exists.": "Environment file already exists.",
        "Environment file not found.": "Environment file not found.",
        "errors": "errors",
        "Everything for the nicest shopping": "Everything for the nicest shopping",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "external url": "external url",
        "Facebook URL": "Facebook URL",
        "Fine workplace": "Fine workplace",
        "First name *": "First name *",
        "Forbidden": "Forbidden",
        "Form": "Form",
        "Form Job Application": "Form Job Application",
        "Go to page {page}": "Go to page {page}",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Hello": "Hello",
        "Hello!": "Hello!",
        "Heroes": "Heroes",
        "Hits": "Hits",
        "Hours": "Hours",
        "hours": "hours",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If url filled in, link will be opend in a new tab. Leave empty if using Sub items": "If url filled in, link will be opend in a new tab. Leave empty if using Sub items",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "Image": "Image",
        "image-left": "image-left",
        "image-right": "image-right",
        "Index Page": "Index Page",
        "Instagram URL": "Instagram URL",
        "Intro Block": "Intro Block",
        "Invalid filename.": "Invalid filename.",
        "Invalid JSON was returned from the route.": "Invalid JSON was returned from the route.",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "Last name *": "Last name *",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "length": "length",
        "LinkedIn URL": "LinkedIn URL",
        "Local assortment": "Local assortment",
        "Local heroes": "local heroes",
        "local heroes": "local heroes",
        "Location": "Location",
        "Login": "Login",
        "Logout": "Logout",
        "Made by:": "Made by:",
        "marker icon": "marker icon",
        "Motivation *": "Motivation *",
        "Name": "name",
        "name": "name",
        "Name *": "Name *",
        "New URL": "New URL",
        "News overview": "News overview",
        "Not Found": "Not Found",
        "Now in our stores": "Now in our stores",
        "of": "of",
        "Old URL": "Old URL",
        "Openings hours": "Openings hours",
        "Openingshours": "Openingshours",
        "Order": "Order",
        "Other local heroes": "Other local heroes",
        "Our": "Our",
        "Our Stores": "Our Stores",
        "Page Expired": "Page Expired",
        "Pagination Navigation": "Pagination Navigation",
        "Parent category": "Parent category",
        "Payment Required": "Payment Required",
        "Permanent": "Permanent",
        "Phone": "Phone",
        "Phone *": "Phone *",
        "Place": "Place",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Postal code": "Postal code",
        "Privacy declaration": "Privacy declaration",
        "Promotion image, will be show if article is visible on homepage, image placed on the right side of the card.": "Promotion image, will be show if article is visible on homepage, image placed on the right side of the card.",
        "Regards": "Regards",
        "Regards,": "Regards,",
        "Register": "Register",
        "Reset Password": "Reset Password",
        "Reset Password Notification": "Reset Password Notification",
        "results": "results",
        "Sales": "Sales",
        "See all heroes": "See all heroes",
        "See all news": "See all news",
        "Send application": "Send application",
        "Send email": "Send email",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Services": "Services",
        "Show this article on the homepage as current promotion": "Show this article on the homepage as current promotion",
        "Showing": "Showing",
        "Slug": "Slug",
        "Social media": "Social media",
        "Special Openings hours": "Special Openings hours",
        "Start": "Start",
        "Stay tuned for fun news, giveaways, offers and more!": "Stay tuned for fun news, giveaways, offers and more!",
        "Store detail overview": "Store detail overview",
        "Store image": "Store image",
        "Store name": "Store name",
        "Stores Compact": "Stores Compact",
        "Street": "Street",
        "Street name": "Street name",
        "Terms and Conditions": "Terms and Conditions",
        "Text": "Text",
        "text": "text",
        "Text left, image right": "Text left, image right",
        "Text right, image left": "Text right, image left",
        "Thank you for your application!": "Thank you for your application!",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The response is not a streamed response.": "The response is not a streamed response.",
        "The response is not a view.": "The response is not a view.",
        "This action is unauthorized.": "This action is unauthorized.",
        "This page does not exist": "This page does not exist",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "Title": "Title",
        "title": "title",
        "Title different opening hours": "Title different opening hours",
        "Title Special Openings hours": "Title Special Openings hours",
        "to": "to",
        "Toggle navigation": "Toggle navigation",
        "Too Many Requests": "Too Many Requests",
        "Type": "Type",
        "Unauthorized": "Unauthorized",
        "Upload C.V": "Upload C.V",
        "Upload C.V *": "Upload C.V *",
        "Upload Motivation": "Upload Motivation",
        "Upload Motivation *": "Upload Motivation *",
        "url": "url",
        "User": "User",
        "Users": "Users",
        "Vacancies": "Vacancies",
        "vacancies": "vacancies",
        "Vacancies Recent": "Vacancies Recent",
        "vacancy": "vacancy",
        "Vacancy Overview": "Vacancy Overview",
        "Vacancyfilters": "Vacancyfilters",
        "Variants": "Variants",
        "Verify Email Address": "Verify Email Address",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved. Of course you can always go to the homepage, one of our departments or view one of our projects, for example.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved. Of course you can always go to the homepage, one of our departments or view one of our projects, for example.",
        "Web Page": "Web Page",
        "Webshop vers bereide producten": "Webshop vers bereide producten",
        "Website": "Website",
        "What kind of work are you looking for?": "What kind of work are you looking for?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "Which store do you want to work at?": "Which store do you want to work at?",
        "Whoops!": "Whoops!",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your message has been sent successfully!": "Your message has been sent successfully!",
        "validation": {
            "accepted": "The {attribute} field must be accepted.",
            "accepted_if": "The {attribute} field must be accepted when {other} is {value}.",
            "active_url": "The {attribute} field must be a valid URL.",
            "after": "The {attribute} field must be a date after {date}.",
            "after_or_equal": "The {attribute} field must be a date after or equal to {date}.",
            "alpha": "The {attribute} field must only contain letters.",
            "alpha_dash": "The {attribute} field must only contain letters, numbers, dashes, and underscores.",
            "alpha_num": "The {attribute} field must only contain letters and numbers.",
            "array": "The {attribute} field must be an array.",
            "ascii": "The {attribute} field must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} field must be a date before {date}.",
            "before_or_equal": "The {attribute} field must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} field must have between {min} and {max} items.",
                "file": "The {attribute} field must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} field must be between {min} and {max}.",
                "string": "The {attribute} field must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "can": "The {attribute} field contains an unauthorized value.",
            "confirmed": "The {attribute} field confirmation does not match.",
            "contains": "The {attribute} field is missing a required value.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} field must be a valid date.",
            "date_equals": "The {attribute} field must be a date equal to {date}.",
            "date_format": "The {attribute} field must match the format {format}.",
            "decimal": "The {attribute} field must have {decimal} decimal places.",
            "declined": "The {attribute} field must be declined.",
            "declined_if": "The {attribute} field must be declined when {other} is {value}.",
            "different": "The {attribute} field and {other} must be different.",
            "digits": "The {attribute} field must be {digits} digits.",
            "digits_between": "The {attribute} field must be between {min} and {max} digits.",
            "dimensions": "The {attribute} field has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} field must not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} field must not start with one of the following: {values}.",
            "email": "The {attribute} field must be a valid email address.",
            "ends_with": "The {attribute} field must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "extensions": "The {attribute} field must have one of the following extensions: {values}.",
            "file": "The {attribute} field must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} field must have more than {value} items.",
                "file": "The {attribute} field must be greater than {value} kilobytes.",
                "numeric": "The {attribute} field must be greater than {value}.",
                "string": "The {attribute} field must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} field must have {value} items or more.",
                "file": "The {attribute} field must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} field must be greater than or equal to {value}.",
                "string": "The {attribute} field must be greater than or equal to {value} characters."
            },
            "hex_color": "The {attribute} field must be a valid hexadecimal color.",
            "image": "The {attribute} field must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field must exist in {other}.",
            "integer": "The {attribute} field must be an integer.",
            "ip": "The {attribute} field must be a valid IP address.",
            "ipv4": "The {attribute} field must be a valid IPv4 address.",
            "ipv6": "The {attribute} field must be a valid IPv6 address.",
            "json": "The {attribute} field must be a valid JSON string.",
            "list": "The {attribute} field must be a list.",
            "lowercase": "The {attribute} field must be lowercase.",
            "lt": {
                "array": "The {attribute} field must have less than {value} items.",
                "file": "The {attribute} field must be less than {value} kilobytes.",
                "numeric": "The {attribute} field must be less than {value}.",
                "string": "The {attribute} field must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} field must not have more than {value} items.",
                "file": "The {attribute} field must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} field must be less than or equal to {value}.",
                "string": "The {attribute} field must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} field must be a valid MAC address.",
            "max": {
                "array": "The {attribute} field must not have more than {max} items.",
                "file": "The {attribute} field must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} field must not be greater than {max}.",
                "string": "The {attribute} field must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} field must not have more than {max} digits.",
            "mimes": "The {attribute} field must be a file of type: {values}.",
            "mimetypes": "The {attribute} field must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} field must have at least {min} items.",
                "file": "The {attribute} field must be at least {min} kilobytes.",
                "numeric": "The {attribute} field must be at least {min}.",
                "string": "The {attribute} field must be at least {min} characters."
            },
            "min_digits": "The {attribute} field must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} field must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} field format is invalid.",
            "numeric": "The {attribute} field must be a number.",
            "password": {
                "letters": "The {attribute} field must contain at least one letter.",
                "mixed": "The {attribute} field must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} field must contain at least one number.",
                "symbols": "The {attribute} field must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "present_if": "The {attribute} field must be present when {other} is {value}.",
            "present_unless": "The {attribute} field must be present unless {other} is {value}.",
            "present_with": "The {attribute} field must be present when {values} is present.",
            "present_with_all": "The {attribute} field must be present when {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} field format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_if_declined": "The {attribute} field is required when {other} is declined.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} field must match {other}.",
            "size": {
                "array": "The {attribute} field must contain {size} items.",
                "file": "The {attribute} field must be {size} kilobytes.",
                "numeric": "The {attribute} field must be {size}.",
                "string": "The {attribute} field must be {size} characters."
            },
            "starts_with": "The {attribute} field must start with one of the following: {values}.",
            "string": "The {attribute} field must be a string.",
            "timezone": "The {attribute} field must be a valid timezone.",
            "ulid": "The {attribute} field must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} field must be uppercase.",
            "url": "The {attribute} field must be a valid URL.",
            "uuid": "The {attribute} field must be a valid UUID.",
            "attributes": {
                "address": "address",
                "affiliate_url": "affiliate URL",
                "age": "age",
                "amount": "amount",
                "announcement": "announcement",
                "area": "area",
                "audience_prize": "audience prize",
                "audience_winner": "audience winner",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "company": "company",
                "compilation": "compilation",
                "concept": "concept",
                "conditions": "conditions",
                "content": "content",
                "contest": "contest",
                "country": "country",
                "cover": "cover",
                "created_at": "created at",
                "creator": "creator",
                "currency": "currency",
                "current_password": "current password",
                "customer": "customer",
                "date": "date",
                "date_of_birth": "date of birth",
                "dates": "dates",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "display_type": "display type",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "finished_at": "finished at",
                "first_name": "first name",
                "gender": "gender",
                "grand_prize": "grand prize",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "image_desktop": "desktop image",
                "image_main": "main image",
                "image_mobile": "mobile image",
                "images": "images",
                "is_audience_winner": "is audience winner",
                "is_hidden": "is hidden",
                "is_subscribed": "is subscribed",
                "is_visible": "is visible",
                "is_winner": "is winner",
                "items": "items",
                "key": "key",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "login": "login",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "portfolio": "portfolio",
                "postal_code": "postal code",
                "preview": "preview",
                "price": "price",
                "product_id": "product ID",
                "product_uid": "product UID",
                "product_uuid": "product UUID",
                "promo_code": "promo code",
                "province": "province",
                "quantity": "quantity",
                "reason": "reason",
                "recaptcha_response_field": "recaptcha response field",
                "referee": "referee",
                "referees": "referees",
                "reject_reason": "reject reason",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "rule": "rule",
                "rules": "rules",
                "second": "second",
                "sex": "sex",
                "shipment": "shipment",
                "short_text": "short text",
                "size": "size",
                "skills": "skills",
                "slug": "slug",
                "specialization": "specialization",
                "started_at": "started at",
                "state": "state",
                "status": "status",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "tag": "tag",
                "tags": "tags",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "type": "type",
                "updated_at": "updated at",
                "user": "user",
                "username": "username",
                "value": "value",
                "winner": "winner",
                "work": "work",
                "year": "year"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The provided password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "passwords": {
            "reset": "Your password has been reset.",
            "sent": "We have emailed your password reset link.",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        }
    },
    "nl": {
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmelding)|(en {count} andere foutmeldingen)|(en {count} andere foutmeldingen)",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "8-12 hours": "8-12 uur",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{count} characters": "{count} characters",
        "A decryption key is required.": "Een decryptiesleutel is verplicht.",
        "About store text": "Over winkel tekst",
        "About store title": "Over winkel titel",
        "About us": "Over ons",
        "Active": "Actief",
        "Active promotion": "Actieve aanbiedingen",
        "Address & contact": "Adres & contact",
        "Administrator": "Beheerder",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "All vacancies": "Alle vacatures",
        "Apply directly": "Solliciteer direct",
        "Apply now": "Solliciteer direct",
        "at Jumbo Bas Bobeldijk": "bij Jumbo Bas Bobeldijk",
        "Available in:": "Verkrijgbaar in: ",
        "Back to home": "Terug naar home",
        "Banner": "Banner",
        "Birthday *": "Geboortedatum *",
        "Birthday:": "Geboortedatum",
        "Choose file": "Kies bestand",
        "City": "Stad",
        "Company name": "Naam van het bedrijf",
        "Contact": "Contact",
        "Contact Cards": "Contactkaarten",
        "Content": "Inhoud",
        "Edit page": "Pagina bewerken",
        "Content Detail Page": "Detailpagina inhoud",
        "Content Repeater": "Content Repeater",
        "Cta": "Cta",
        "Current Actions": "Huidige acties",
        "Dear": "Beste",
        "Dear {name},": "Beste {name}",
        "Delete filters": "Filters wissen",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Different opening hours": "Afwijkende openingstijden",
        "Different openings hours": "Afwijkende openingstijden",
        "Different openingshours": "Afwijkende openingstijden",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "E-mail": "E-mail",
        "E-mail *": "E-mail *",
        "Email": "E-mail",
        "Encrypted environment file already exists.": "Versleuteld environment-bestand bestaat al.",
        "Encrypted environment file not found.": "Versleuteld environment-bestand niet gevonden.",
        "End": "Einde",
        "Enter working hours here": "Vul hier de werkuren in",
        "Environment file already exists.": "Environment-bestand bestaat al.",
        "Environment file not found.": "Environment-bestand niet gevonden.",
        "errors": "fouten",
        "Everything for the nicest shopping": "Alles voor de leukste boodschap",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "external url": "external url",
        "Facebook URL": "Facebook URL",
        "Fine workplace": "Fijne werkplek",
        "First name *": "Voornaam *",
        "First name:": "Voornaam:",
        "Forbidden": "Geen toegang",
        "Form": "Formulier",
        "Form Job Application": "Formulier sollicitatie",
        "Go to page {page}": "Ga naar pagina {page}",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Hello": "Hallo",
        "Hello!": "Hallo!",
        "Heroes": "Lokale Helden",
        "Hits": "Hits",
        "Hours": "Uur",
        "hours": "uur",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If url filled in, link will be opend in a new tab. Leave empty if using Sub items": "If url filled in, link will be opend in a new tab. Leave empty if using Sub items",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hieronder\nin je webbrowser:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "Image": "Afbeelding",
        "image-left": "Afbeelding links",
        "image-right": "Afbeelding rechts",
        "Index Page": "Indexpagina",
        "Instagram URL": "Instagram URL",
        "Intro Block": "Intro Blok",
        "Invalid filename.": "Ongeldige bestandsnaam.",
        "Invalid JSON was returned from the route.": "Er is ongeldige JSON teruggekomen van de route.",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribuut} tussen {min} en {max} tekens lang te houden.",
        "Last name *": "Achternaam *",
        "Last name:": "Achternaam:",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "length": "lengte",
        "LinkedIn URL": "LinkedIn URL",
        "Local assortment": "Lokaal assortiment",
        "Local heroes": "Lokale helden",
        "local heroes": "lokale helden",
        "Location": "Locatie",
        "Login": "Inloggen",
        "Logout": "Uitloggen",
        "Made by:": "Gerealiseerd door:",
        "marker icon": "markeringspictogram",
        "Motivation *": "Motivatie *",
        "Name": "Naam",
        "name": "naam",
        "Name *": "Naam *",
        "New application from": "Nieuwe sollicitatie van ",
        "New URL": "Nieuwe URL",
        "News overview": "Nieuwsoverzicht",
        "Not Found": "Niet gevonden",
        "Now in our stores": "Nu in onze winkels",
        "of": "van",
        "Old URL": "Oude URL",
        "Openings hours": "Openingstijden",
        "Openingshours": "Openingstijden",
        "Order": "Bestel",
        "Other local heroes": "Andere lokale helden",
        "Our": "Onze",
        "Our Stores": "Onze winkels",
        "Page Expired": "Pagina niet meer geldig",
        "Pagination Navigation": "Paginanavigatie",
        "Parent category": "Hoofdcategorie",
        "Payment Required": "Betaling vereist",
        "Permanent": "Permanent",
        "Phone": "Telefoon",
        "Phone *": "Telefoon *",
        "Phone:": "Telefoon:",
        "Place": "Woonplaats",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Postal code": "Postcode",
        "Privacy declaration": "Privacyverklaring",
        "Promotion image, will be show if article is visible on homepage, image placed on the right side of the card.": "Promotie afbeelding, wordt getoond als artikel zichtbaar is op homepage, afbeelding geplaatst aan de rechterkant van de kaart.",
        "Regards": "Met vriendelijke groet",
        "Regards,": "Met vriendelijke groet,",
        "Register": "Registreren",
        "Reset Password": "Wachtwoord herstellen",
        "Reset Password Notification": "Notificatie wachtwoordherstel",
        "results": "resultaten",
        "Sales": "Aanbiedingen",
        "See all heroes": "Bekijk alle helden",
        "See all news": "Bekijk alle nieuws",
        "Send application": "Sollicitatie versturen",
        "Send email": "E-mail versturen",
        "SEO title": "SEO titel",
        "Server Error": "Serverfout",
        "Service Unavailable": "Website onbeschikbaar",
        "Services": "Diensten",
        "Show this article on the homepage as current promotion": "Laat dit artikel zien op de homepagina als huidige actie",
        "Showing": "Toont",
        "Slug": "Permalink",
        "Social media": "Sociale media",
        "Special Openings hours": "Afwijkende openingstijden",
        "Start": "Start",
        "Stay tuned for fun news, giveaways, offers and more!": "Blijf kijken voor leuke nieuwtjes, giveaways, aanbiedingen en meer!",
        "Store detail overview": "Winkel detailoverzicht",
        "Store image": "Winkel afbeelding",
        "Store name": "Naam winkel",
        "Stores Compact": "Winkels Compact",
        "Street": "Straat",
        "Street name": "Straatnaam",
        "Terms and Conditions": "Algemene Voorwaarden",
        "Text": "Tekst",
        "text": "tekst",
        "Text left, image right": "Tekst links, afbeelding rechts",
        "Text right, image left": "Tekst rechts, afbeelding links",
        "Thank you for your application!": "Bedankt voor je sollicitatie!",
        "Thank you for your application. We will contact you shortly.": "Bedankt voor je sollicitatie. Wij zullen binnenkort contact met je opnemen.",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The response is not a streamed response.": "De respons is niet gestreamd.",
        "The response is not a view.": "De respons is geen view.",
        "The vacancyform has been filled in. See all details below.": "Het vacatureformulier is ingevuld. Bekijk alle details hieronder.",
        "This action is unauthorized.": "Deze actie is niet toegestaan.",
        "This page does not exist": "Deze pagina bestaat niet",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "Title": "Titel",
        "title": "titel",
        "Title different opening hours": "Titel afwijkende openingstijden",
        "Title Special Openings hours": "Titel afwijkende openingstijden",
        "to": "tot",
        "Toggle navigation": "Schakel navigatie",
        "Too Many Requests": "Te veel serververzoeken",
        "Type": "Type",
        "Unauthorized": "Onbevoegd",
        "Upload C.V": "Upload C.V",
        "Upload C.V *": "Upload C.V *",
        "Upload Motivation": "Motivatie uploaden",
        "Upload Motivation *": "Motivatie uploaden *",
        "url": "url",
        "User": "Gebuiker",
        "Users": "Gebruikers",
        "Vacancies": "Vacatures",
        "vacancies": "vacatures",
        "Vacancies Recent": "Recente vacatures",
        "Vacancy": "Vacature",
        "vacancy": "vacature",
        "Vacancy Overview": "Overzicht vacatures",
        "Vacancyfilters": "Vacaturefilters",
        "Variants": "Varianten",
        "Verify Email Address": "Verifieer e-mailadres",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved. Of course you can always go to the homepage, one of our departments or view one of our projects, for example.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze verplaatst is. U kunt natuurlijk altijd naar de homepage, een van onze afdelingen of bijvoorbeeld een van onze projecten gaan.",
        "Webshop vers bereide producten": "Webshop vers bereide producten",
        "Website": "Website",
        "What kind of work are you looking for?": "Wat voor werk zoek je?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "Which store do you want to work at?": "Bij welke winkel wil je werken?",
        "Whoops!": "Oeps!",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "Your account for {siteName}": "Je account voor {siteName}",
        "Your message has been sent successfully!": "Uw bericht is succesvol verzonden!",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "can": "{Attribute} bevat een waarde waar je niet bevoegd voor bent.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "contains": "{Attribute} mist een vereiste waarde.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "extensions": "{Attribute} moet een van de volgende bestandsextensies hebben: {values}.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "hex_color": "{Attribute} moet een geldige hexadecimale kleurcode zijn.",
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "list": "Het veld {attribute} moet een lijst zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "present_if": "{Attribute} moet aanwezig zijn als {other} {value} is.",
            "present_unless": "{Attribute} moet aanwezig zijn tenzij {other} {value} is.",
            "present_with": "{Attribute} moet aanwezig zijn als {values} aanwezig is.",
            "present_with_all": "{Attribute} moet aanwezig zijn als {values} aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_if_declined": "{Attribute} is verplicht indien {other} is geweigerd.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "affiliate_url": "partner-URL",
                "age": "leeftijd",
                "amount": "bedrag",
                "announcement": "aankondiging",
                "area": "gebied",
                "audience_prize": "publieksprijs",
                "audience_winner": "audience winner",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "inhoud",
                "city": "stad",
                "company": "company",
                "compilation": "compilatie",
                "concept": "concept",
                "conditions": "voorwaarden",
                "content": "inhoud",
                "contest": "contest",
                "country": "land",
                "cover": "omslag",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "currency": "valuta",
                "current_password": "huidig wachtwoord",
                "customer": "klant",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "dates": "datums",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "display_type": "weergavetype",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "finished_at": "klaar om",
                "first_name": "voornaam",
                "gender": "geslacht",
                "grand_prize": "grote prijs",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "image_desktop": "bureaubladafbeelding",
                "image_main": "hoofdafbeelding",
                "image_mobile": "mobiele afbeelding",
                "images": "afbeeldingen",
                "is_audience_winner": "is publiekswinnaar",
                "is_hidden": "is verborgen",
                "is_subscribed": "is geabonneerd",
                "is_visible": "is zichtbaar",
                "is_winner": "is winnaar",
                "items": "artikels",
                "key": "sleutel",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "login": "login",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "portfolio": "portfolio",
                "postal_code": "postcode",
                "preview": "voorbeeld",
                "price": "prijs",
                "product_id": "product-ID",
                "product_uid": "product-UID",
                "product_uuid": "product-UUID",
                "promo_code": "promo-code",
                "province": "provincie",
                "quantity": "hoeveelheid",
                "reason": "reden",
                "recaptcha_response_field": "recaptcha-antwoordveld",
                "referee": "scheidsrechter",
                "referees": "scheidsrechters",
                "reject_reason": "afwijsreden",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "rule": "regel",
                "rules": "regels",
                "second": "seconde",
                "sex": "geslacht",
                "shipment": "verzending",
                "short_text": "korte tekst",
                "size": "grootte",
                "skills": "vaardigheden",
                "slug": "slug",
                "specialization": "specialisatie",
                "started_at": "gestart op",
                "state": "staat",
                "status": "status",
                "street": "straat",
                "student": "leerling",
                "subject": "onderwerp",
                "tag": "label",
                "tags": "labels",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "testomschrijving",
                "test_locale": "testlandinstelling",
                "test_name": "testnaam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "type": "type",
                "updated_at": "bijgewerkt op",
                "user": "gebruiker",
                "username": "gebruikersnaam",
                "value": "waarde",
                "winner": "winner",
                "work": "work",
                "year": "jaar"
            }
        },
        "routes": {
            "news": "nieuws",
            "local-heroes": "lokale-helden",
            "vacancies": "vacatures",
            "stores": "winkels"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Dit wachtwoordhersteltoken is niet geldig.",
            "user": "Geen gebruiker bekend met dit e-mailadres."
        }
    }
}
